export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47')
];

export const server_loads = [];

export const dictionary = {
		"/": [3],
		"/LCVP_browse": [5],
		"/LCVP_display": [6],
		"/LCVP": [4],
		"/about": [7],
		"/analyse": [8],
		"/downloads": [9],
		"/explore": [10],
		"/iknow": [11,[2]],
		"/iknow/about": [12,[2]],
		"/iknow/cleaninginit": [13,[2]],
		"/iknow/cleaningresult": [14,[2]],
		"/iknow/cpamapping": [15,[2]],
		"/iknow/downloading": [16,[2]],
		"/iknow/kg_visualization/visualization": [17,[2]],
		"/iknow/linkinginit": [18,[2]],
		"/iknow/linkingresult": [19,[2]],
		"/iknow/main": [20,[2]],
		"/iknow/main/querybuilder": [21,[2]],
		"/iknow/main/querycatalogue": [22,[2]],
		"/iknow/main/sparqlquery": [23,[2]],
		"/iknow/projectcreation": [24,[2]],
		"/iknow/projectinit": [25,[2]],
		"/iknow/provenance": [26,[2]],
		"/iknow/provenance/change_and_rerun": [27,[2]],
		"/iknow/provenance/rerun": [28,[2]],
		"/iknow/provenance/retrieve_result": [30,[2]],
		"/iknow/provenance/retrieve": [29,[2]],
		"/iknow/querybuilder": [31,[2]],
		"/iknow/querybuilding": [32,[2]],
		"/iknow/schemarefinement": [33,[2]],
		"/iknow/sgpcinfo": [34,[2]],
		"/iknow/wiki/classes": [35,[2]],
		"/iknow/wiki/instancesOf": [36,[2]],
		"/iknow/wiki/properties": [37,[2]],
		"/imprint": [38],
		"/learn": [39],
		"/lifegate": [40],
		"/login": [41],
		"/logout": [42],
		"/register": [43],
		"/search": [44],
		"/smon_viz": [45],
		"/test": [46],
		"/workflows": [47]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';